import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SurveyIllustration from "resources/images/survey-illustration.png";
import Button from "app/storybookComponents/Button";
import { Team, TeamMember } from "app/containers/Global/types";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  selectDepartments,
  selectCompanySettings,
} from "app/containers/AdminConsole/slice";
import { Card, Nav, Form } from "react-bootstrap";
import TeamTable from "./TeamTable";
import TeamSettingsModal from "../Modals/TeamSettingsModal";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { responseStatus } from "utils/types";
import AvatarCircle from "app/components/AvatarCircle";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import {
  selectUpdateTeamStatus,
  editTeamMemberFromTeam,
  removeTeamMemberFromTeam,
  selectUpdateTeamMemberByTeamMemberIdStatus,
  selectRemoveTeamMemberFromTeamStatus,
} from "app/containers/Global/slice";
import Select from "react-select";
import SurveysTable from "./SurveysTable";
import {
  getTeamAssessmentInstances,
  selectTeamVisibilitySettings,
  updateTeamVisibility,
} from "../slice";
import { TeamVisibilitySettingsLabelMap } from "../constants";
import { showScheduleAssessmentModalForTeamId } from "app/components/LaunchAssessmentModal/slice";
import AdminTablesHeader from "app/containers/AdminConsole/AdminTablesHeader";
import ChecklistDropdown from "app/components/Dropdowns/ChecklistDropdown";
import { TeamVisibilitySetting, TeamVisibilitySettings } from "../types";
import { useDebounceCallback } from "utils/hooks";

interface Props {
  teamInfo?: Team;
  teamMembers?: TeamMember[];
  showInviteModal: () => void;
  onDeleteTeam: () => void;
  deleteTeamStatus: responseStatus;
  onUpdateTeam: (team: Partial<Team>) => void;
}

// TODO: Need to create modal to show who can invite people to the team and display those members who already have access.
export default function TeamSettings({
  teamInfo,
  teamMembers,
  showInviteModal,
  onDeleteTeam,
  deleteTeamStatus,
  onUpdateTeam,
}: Readonly<Props>) {
  // --------------------- Selectors ---------------------
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTeamStatus = useAppSelector(selectUpdateTeamStatus);
  const updateTeamMemberStatus = useAppSelector(
    selectUpdateTeamMemberByTeamMemberIdStatus
  );
  const removeTeamMemberStatus = useAppSelector(
    selectRemoveTeamMemberFromTeamStatus
  );
  const departments = useAppSelector(selectDepartments);
  const companySettings = useAppSelector(selectCompanySettings);
  const teamVisibilitySettings = useAppSelector(
    selectTeamVisibilitySettings(teamInfo?.teamId ?? 0)
  );

  // --------------------- UseState ---------------------
  const [editTeamModal, setEditTeamModal] = useState<
    null | "Name" | "Department" | "Who Can Invite"
  >(null);
  const [tabShowing, setTabShowing] = useState<
    "members" | "general" | "surveys"
  >("general");

  const [showWarningModal, setShowWarningModal] = useState<
    | false
    | ({
        warningTitle: string;
        warningMessage: string;
        extraBody?: React.ReactNode;
      } & (
        | {
            modalTitle: "Change Permissions";
            isDanger: false;
            customButtonText: "Confirm Changes";
            teamMemberId: number;
            isTeamLead: 0 | 1;
            userAccountId: number;
          }
        | {
            modalTitle: "Remove Team Member";
            isDanger: true;
            customButtonText: "Remove";
            teamMemberId: number;
            userAccountId: number;
          }
        | {
            modalTitle: "Deactivate Team";
            isDanger: true;
            customButtonText: "Deactivate Team";
          }
      ))
  >(false);
  const [value, setValue] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState<boolean>(!!teamInfo?.private);
  const [membersInTeam, setMembersInTeam] = useState<
    (TeamMember & { isPending?: boolean })[]
  >([]);
  const [team360ResultsVisibility, setTeam360ResultsVisibility] =
    useState<TeamVisibilitySetting>({});
  const [
    teamPersonalityVisibilitySetting,
    setTeamPersonalityVisibilitySetting,
  ] = useState<TeamVisibilitySetting>({});

  // --------------------- UseEffect ---------------------

  useLayoutEffect(() => {
    if (!teamInfo) {
      return;
    }

    const newMembers: {
      [key: string]: TeamMember & { isPending?: boolean };
    } = {};

    teamInfo.teamMembers?.forEach((teamMember) => {
      newMembers[teamMember.userAccountId] = teamMember;
    });

    teamInfo.pendingTeamMemberIds?.forEach((userAccountId) => {
      if (!newMembers[userAccountId]) {
        return;
      }
      newMembers[userAccountId] = {
        ...newMembers[userAccountId],
        isPending: true,
      };
    });
    setIsPrivate(!!teamInfo.private);
    setMembersInTeam(Object.values(newMembers));
  }, [teamInfo]);

  useEffect(() => {
    if (updateTeamStatus === "succeeded") {
      setEditTeamModal(null);
    }
  }, [updateTeamStatus]);

  useEffect(() => {
    if (updateTeamMemberStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [updateTeamMemberStatus]);

  useEffect(() => {
    if (removeTeamMemberStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [removeTeamMemberStatus]);

  useLayoutEffect(() => {
    const eventKey = searchParams.get("teamSettingsActiveTab");
    if (
      eventKey === "members" ||
      eventKey === "general" ||
      eventKey === "surveys"
    ) {
      setTabShowing(eventKey);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!teamVisibilitySettings) return;
    setTeam360ResultsVisibility(teamVisibilitySettings["Team TEAMscan"]);
    setTeamPersonalityVisibilitySetting(
      teamVisibilitySettings["Team Personality"]
    );
  }, [teamVisibilitySettings]);

  useEffect(() => {
    if (!teamInfo?.teamId) return;
    dispatch(getTeamAssessmentInstances({ teamId: teamInfo?.teamId }));
  }, [dispatch, teamInfo]);

  // --------------------- Functions ---------------------

  const onSaveTeamScanVisibilitySetting = useDebounceCallback(
    (payload: TeamVisibilitySettings["Team TEAMscan"]) => {
      dispatch(
        updateTeamVisibility({
          teamId: teamInfo?.teamId ?? 0,
          visibilitySetting: {
            "Team TEAMscan": payload,
          },
        })
      );
    },
    1000
  );

  const onSaveTeamPersonalityVisibilitySetting = useDebounceCallback(
    (payload: TeamVisibilitySettings["Team Personality"]) => {
      dispatch(
        updateTeamVisibility({
          teamId: teamInfo?.teamId ?? 0,
          visibilitySetting: {
            "Team Personality": payload,
          },
        })
      );
    },
    1000
  );

  const getTabContent = () => {
    switch (tabShowing) {
      case "members":
        return getMemberTabContent();
      case "general":
        return getGeneralSettingsContent();
      case "surveys":
        return getSurveyTabContent();
    }
  };

  const getDepartmentName = () =>
    teamInfo?.departmentId
      ? departments[teamInfo?.departmentId ?? 0]?.name ?? "No Department"
      : "No Department";

  const getVisibilitySettingValue = (setting: TeamVisibilitySetting) => {
    if (setting["Organization Member"]) {
      return "Everyone in this organization";
    }
    const keys: string[] = [];
    Object.entries(setting).forEach(([key, value]) => {
      if (value) {
        keys.push(TeamVisibilitySettingsLabelMap[key] ?? key);
      }
    });

    return keys.join(", ");
  };

  const getChangePermissionWarningMessageAndTitle = (
    isTeamLead: boolean
  ): {
    warningTitle: string;
    warningMessage: string;
  } => {
    const teamMemberMessage =
      "This person will no longer be able to invite/remove members, change permissions, and configure team settings";
    const teamLeaderMessage =
      "Team leaders have the ability to invite/remove members, change permissions, and configure team settings";

    const warningTitle = `Are you sure you want to assign this person as a team ${
      isTeamLead ? "leader" : "member"
    }?`;
    const warningMessage = isTeamLead ? teamLeaderMessage : teamMemberMessage;

    return {
      warningTitle,
      warningMessage,
    };
  };

  const getMemberTabContent = () => {
    if (!teamInfo) return null;
    return (
      <>
        <Form.Group className="row-gap-20px">
          <Form.Control
            placeholder={"Search by name or email..."}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button onClick={showInviteModal} style={{ whiteSpace: "nowrap" }}>
            <FontAwesomeIcon icon="user-plus" className="me-2" />
            Invite
          </Button>
        </Form.Group>
        <TeamTable
          teamId={teamInfo?.teamId}
          teamMembers={membersInTeam ?? []}
          searchValue={value}
          updateTeamMember={(teamMemberId, payload) => {
            const memberInfo = teamMembers?.find(
              (teamMember) => teamMember.teamMemberId === teamMemberId
            );
            const { warningTitle, warningMessage } =
              getChangePermissionWarningMessageAndTitle(!!payload.isTeamLead);

            setShowWarningModal({
              warningTitle,
              warningMessage,
              modalTitle: "Change Permissions",
              isDanger: false,
              customButtonText: "Confirm Changes",
              teamMemberId,
              isTeamLead: payload.isTeamLead,
              userAccountId: memberInfo?.userAccountId ?? 0,
            });
          }}
          removeTeamMember={(teamMemberId) => {
            const memberInfo = teamMembers?.find(
              (teamMember) => teamMember.teamMemberId === teamMemberId
            );
            setShowWarningModal({
              warningTitle: `Are you sure you want to remove this person from ${teamInfo?.teamName}?`,
              warningMessage:
                "This person will no longer be able to access information viewable only to the team or take this team's assessments.",
              modalTitle: "Remove Team Member",
              isDanger: true,
              customButtonText: "Remove",
              teamMemberId,
              userAccountId: memberInfo?.userAccountId || 0,
            });
          }}
        />
      </>
    );
  };

  const getSurveyTabContent = () => {
    if (!teamInfo?.teamId) return null;
    return (
      <>
        <AdminTablesHeader
          bannerDescription="Send the TEAMscan survey to your team"
          bannerTitle="Launch the TEAMscan survey"
          iconImageSrc={SurveyIllustration}
          iconImageAlt="Launch TEAMscan"
          actionButton={
            <div className="row-gap-12px">
              <Button
                onClick={() => {
                  dispatch(
                    showScheduleAssessmentModalForTeamId(teamInfo?.teamId)
                  );
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                onClick={() => {
                  navigate("/SkillsGuide");
                }}
                variant="secondary-blue"
                className="white-button"
              >
                See dimensions
              </Button>
            </div>
          }
        />
        <p
          style={{
            margin: "4px 0",
          }}
        >
          Monitor completion and edit survey settings for TEAMscans here. To
          view results or edit settings, click the menu icon to find more
          actions for each instance
        </p>
        <SurveysTable teamId={teamInfo?.teamId} />
      </>
    );
  };

  const getDepartmentRow = () => {
    if (companySettings?.areDepartmentsHidden) {
      return null;
    }
    return (
      <div className="setting-row">
        <div className="setting-row-description">
          <h3>Department</h3>
          <p>{getDepartmentName()}</p>
        </div>
        <div className="setting-row-action">
          <Button
            variant="secondary-blue"
            onClick={() => setEditTeamModal("Department")}
          >
            Edit Department
          </Button>
        </div>
      </div>
    );
  };

  const onVisibilityChecklistDropdownSelect = (
    selectedItems: TeamVisibilitySetting,
    setter: (value: TeamVisibilitySetting) => void,
    selectedKey?: string | number,
    alwaysOnSettings?: TeamVisibilitySetting
  ) => {
    // If the user selects "Organization Member" and its selected then we need to select all the other options
    if (selectedKey === "Organization Member" && selectedItems[selectedKey]) {
      return setter({
        "Organization Member": 1,
        Admin: 1,
        "Team Leader": 1,
        "Team Member": 1,
        "Department Leader": 1,
        ...alwaysOnSettings,
      });
    }

    // If the user deselects any of the other options then we should deselect "Organization Member"
    if (
      (selectedKey === "Team Member" ||
        selectedKey === "Team Leader" ||
        selectedKey === "Department Leader") &&
      !selectedItems[selectedKey]
    ) {
      return setter({
        ...selectedItems,
        [selectedKey]: selectedItems[selectedKey],
        "Organization Member": 0,
      });
    }

    setter(selectedItems);
  };

  const getGeneralSettingsContent = () => {
    const publicSetting = {
      value: "0",
      label: "Everyone in this organization",
    };
    const privateSetting = {
      value: "1",
      label: "Admins and team members on this team",
    };

    const isOverrideDisabled =
      !companySettings?.allowVisibilityPermissionOverride;
    const lockIcon = isOverrideDisabled ? (
      <FontAwesomeIcon
        icon={["far", "lock-keyhole"]}
        style={{ color: "#805100" }}
      />
    ) : null;

    const alwaysOnSettings = {
      Admin: 1,
      "Team Leader": 1,
    } as TeamVisibilitySetting;

    return (
      <div className="column-gap-20px">
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Team Name</h3>
            <p>{teamInfo?.teamName}</p>
          </div>
          <div className="setting-row-action">
            <Button
              variant="secondary-blue"
              onClick={() => setEditTeamModal("Name")}
            >
              Edit Team Name
            </Button>
          </div>
        </div>
        {getDepartmentRow()}
        <hr className="m-0" />
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Team visibility</h3>
            <p>
              Either allow everyone in this organization (public) or only allow
              admins and this team's members to view this team guide (private).
            </p>
          </div>
          <div className="setting-row-action">
            <Select
              options={[publicSetting, privateSetting]}
              value={isPrivate ? privateSetting : publicSetting}
              onChange={(value) => {
                setIsPrivate(value?.value === "1");
                onUpdateTeam({
                  private: value?.value === "1" ? 1 : 0,
                });
              }}
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can view Team Personality?</h3>
            <p>
              Specify which users can view the Team Personality for this team.
            </p>
          </div>
          <div className="setting-row-action">
            {lockIcon}

            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                {
                  id: "Team Leader",
                  label: "This team's leader(s)",
                  disabled: true,
                },
                { id: "Team Member", label: "Team members on this team" },
                {
                  id: "Department Leader",
                  label: "This team's department leader",
                },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={{
                ...teamPersonalityVisibilitySetting,
                ...alwaysOnSettings,
              }}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setTeamPersonalityVisibilitySetting,
                  ck,
                  alwaysOnSettings
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue({
                ...teamPersonalityVisibilitySetting,
                ...alwaysOnSettings,
              })}
              onUnFocus={(pl) => onSaveTeamPersonalityVisibilitySetting(pl)}
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see TEAMscan results?</h3>
            <p>
              Specify which users can view the TEAMscan results for this team.
            </p>
          </div>
          <div className="setting-row-action">
            {lockIcon}
            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                {
                  id: "Team Leader",
                  label: "This team's leader(s)",
                  disabled: true,
                },
                { id: "Team Member", label: "Team members on this team" },
                {
                  id: "Department Leader",
                  label: "This team's department leader",
                },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={{
                ...team360ResultsVisibility,
                ...alwaysOnSettings,
              }}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setTeam360ResultsVisibility,
                  ck,
                  alwaysOnSettings
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue({
                ...team360ResultsVisibility,
                ...alwaysOnSettings,
              })}
              onUnFocus={(pl) => onSaveTeamScanVisibilitySetting(pl)}
            />
          </div>
        </div>
        {isOverrideDisabled ? (
          <div className="warning-banner gold row-gap-8px align-items-center">
            {lockIcon}
            <p>
              An admin has turned off the ability to override visibility
              defaults set for this organization. Please reach out to an admin
              to change visibility settings.
            </p>
          </div>
        ) : null}

        <hr className="m-0" />
        <div className="column-gap-10px">
          <h3
            style={{
              color: "black",
            }}
          >
            Deactivate Team
          </h3>
          <p>Remove all team members and permanently delete team guide</p>
          <div>
            <Button
              variant="secondary-danger"
              onClick={() =>
                setShowWarningModal({
                  warningTitle: `Are you sure you want to deactivate "${teamInfo?.teamName}"?`,
                  warningMessage:
                    "This will permanently delete all information on this team guide and remove this team from the company directory. This action cannot be undone.",
                  modalTitle: "Deactivate Team",
                  isDanger: true,
                  customButtonText: "Deactivate Team",
                })
              }
              disabled={deleteTeamStatus === "loading"}
            >
              Deactivate Team
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getWarningModalInProgress = () => {
    if (showWarningModal === false) {
      return false;
    }

    switch (showWarningModal.modalTitle) {
      case "Deactivate Team":
        return deleteTeamStatus === "loading";
      case "Change Permissions":
        return updateTeamMemberStatus === "loading";
      case "Remove Team Member":
        return removeTeamMemberStatus === "loading";
      default:
        return false;
    }
  };

  // ------------ "On Action" Functions ------------
  const onWarningConfirmClick = () => {
    if (showWarningModal === false || !teamInfo) {
      return false;
    }

    switch (showWarningModal.modalTitle) {
      case "Deactivate Team":
        return onDeleteTeam();
      case "Change Permissions":
        return dispatch(
          editTeamMemberFromTeam({
            payload: {
              isTeamLead: showWarningModal.isTeamLead,
            },
            teamMemberId: showWarningModal.teamMemberId,
            teamId: teamInfo.teamId,
            userAccountId: showWarningModal.userAccountId,
          })
        );
      case "Remove Team Member":
        return dispatch(
          removeTeamMemberFromTeam({
            teamMemberId: showWarningModal.teamMemberId,
            teamId: teamInfo.teamId,
            userAccountId: showWarningModal.userAccountId,
          })
        );
      default:
        return false;
    }
  };

  return (
    <>
      <TeamSettingsModal
        teamInfo={teamInfo}
        modalShowing={editTeamModal}
        onSave={(payload) => onUpdateTeam(payload)}
        hideModal={() => setEditTeamModal(null)}
        loading={updateTeamStatus === "loading"}
      />
      {showWarningModal !== false ? (
        <WarningModal
          modalTitle={showWarningModal.modalTitle}
          warningTitle={showWarningModal.warningTitle}
          warningMessage={showWarningModal.warningMessage}
          hideModal={() => setShowWarningModal(false)}
          onConfirmClick={onWarningConfirmClick}
          isInProgress={getWarningModalInProgress()}
          isDanger={showWarningModal.isDanger}
          customButtonText={showWarningModal.customButtonText}
          extraBody={showWarningModal.extraBody}
          isOpen
        />
      ) : null}
      <div style={{ marginBottom: "20px" }}>
        <Button
          onClick={() => navigate(-1)}
          variant="secondary-blue"
          style={{ border: "none" }}
        >
          <FontAwesomeIcon icon="arrow-left" className="me-2" />
          Back
        </Button>
      </div>
      <div className="column-gap-20px">
        <Card className="team-settings column-gap-20px">
          <div className="d-flex justify-content-between align-items-center">
            <div className="team-settings-header row-gap-20px">
              <Link
                className="no-underline"
                to={`/TeamGuide/${teamInfo?.teamId}`}
              >
                <AvatarCircle
                  name={teamInfo?.teamName || ""}
                  avatarColor={teamInfo?.avatarColor}
                  profilePicture={teamInfo?.profilePicture}
                  size="large"
                />
              </Link>
              <div className="row-gap-12px align-items-end">
                <Link
                  className="no-underline"
                  to={`/TeamGuide/${teamInfo?.teamId}`}
                >
                  <h1>{teamInfo?.teamName || ""}</h1>
                </Link>
                <span className="team-member-count">
                  {teamMembers?.length || 0} Member
                  {teamMembers && teamMembers?.length > 1 ? "s" : ""}
                </span>
              </div>
            </div>
            <div>
              <Button
                variant="secondary-blue"
                onClick={() => {
                  navigate(`/TeamGuide/${teamInfo?.teamId}`);
                }}
              >
                Go to team guide
              </Button>
            </div>
          </div>
          <Nav
            className="simple-nav"
            activeKey={tabShowing || ""}
            onSelect={(e) => {
              if (!e) return;
              setTabShowing(e as "members" | "general" | "surveys");
              setSearchParams((searchParams) => {
                searchParams.set("teamSettingsActiveTab", e);
                return searchParams;
              });
            }}
            style={{
              marginBottom: "-20px",
            }}
          >
            <Nav.Item>
              <Nav.Link eventKey="members">Members</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="surveys">Surveys</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="general">General</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card>
        <Card className="team-settings column-gap-20px">{getTabContent()}</Card>
      </div>
    </>
  );
}
