import {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  ComponentType,
  PropsWithChildren,
} from "react";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTeam360Scores,
  selectAllTeam360Results,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectSampleTeamsByTeamId,
  selectCompanyInfo,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TTeam360Factor } from "app/components/Team360Assessment/types";
import Team360ResultsModal from "app/components/Team360Assessment/Team360ResultsModal/Team360ResultsModal";
import Loading from "app/storybookComponents/Loading";
import EmptyTeam360ResultsCard from "app/components/Team360Assessment/EmptyTeam360ResultsCard";
import TeamSummaryCard from "app/components/TEAM360InsightsModules/TeamSummaryCard";
import ResultsOverviewCard from "app/components/TEAM360InsightsModules/ResultsOverviewCard";
import InsightReportHeader from "app/components/TEAM360InsightsModules/InsightReportHeader";
import ManagersVsTeamMembers from "app/components/TEAM360InsightsModules/ManagerVsTeamMembers";
import GenderInsightsCard from "app/components/TEAM360InsightsModules/GenderInsightsCard";
import TeamReflectionCard from "app/components/TEAM360InsightsModules/TeamReflectionCard";
import DepartmentSummaryCard from "app/components/TEAM360InsightsModules/DepartmentSummaryCard";
import Team360ScoreBreakdownModal from "app/components/Team360Assessment/Team360ScoreBreakdownModal";
import {
  selectAnalyticsText,
  selectGetAnalyticsTextStatus,
  getAnalyticsText as getAnalyticsTextAction,
  selectSampleAnalyticsText,
  selectGetSampleAnalyticsTextStatus,
  getSampleAnalyticsText as getSampleAnalyticsTextAction,
  getGenderInsights,
  selectGenderInsights,
  selectGetGenderInsightsStatus,
  selectTextSynthesis,
  selectGetTextSynthesisStatus,
  selectDepartments,
  getOrganizationComments,
  selectGetOrganizationCommentsStatus,
  selectOrganizationComments,
  getSampleOrganizationComments,
  selectGetSampleOrganizationCommentsStatus,
  selectSampleOrganizationComments,
  updateTeam360DateRange,
  selectUpdateTeam360DateRangeStatus,
  resetUpdateTeam360DateRangeStatus,
  selectOrganizationTeamAssessmentInstances,
  selectTeam360CardsExpanded,
  setTeam360CardsExpanded,
  selectTeam360InstancePicked,
  selectIsDepartmentsHidden,
  selectGetTeamAssessmentInstancesStatus,
  getTeamAssessmentInstances,
  selectCompanySettings,
} from "./slice";
import Team360ComparisonModal from "app/components/Team360Assessment/Team360ComparisonModal";
import Team360CommentsByYourTeamModal from "app/components/Team360Assessment/Team360CommentsByYourTeamModal";
import Team360AgreementReportModal from "app/components/Team360Assessment/Team360AgreementReportModal";
import { Card, Dropdown } from "react-bootstrap";
import WhatSkillsCanYourTeamWorkOn from "app/components/Team360Assessment/WhatSkillsCanYourTeamWorkOn";
import {
  selectGenderInsightsByDepartmentId,
  getGenderInsights as getDepartmentGenderInsights,
} from "app/containers/DepartmentInsightReport/slice";
import DateRangePicker from "app/components/InstancePicker/DateRangePicker";
import FreeTextSummary from "./FreeTextSummary";
import InstanceBanner from "../DepartmentInsightReport/InstanceBanner";
import SampleBanner from "app/components/Team360Assessment/SampleBanner";
import { setIsQuickActionDrawerOpen } from "app/components/QuickActions/slice";
import TeamScanActionChecklistDrawer from "app/components/Checklists/TeamScanActionChecklistDrawer";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "../Chatbot/slice";
import CustomChatbotButton from "../Chatbot/CustomChatbotButton";
import { ORG_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE } from "../CoachBotPrompts/constants";
import VisibilityBanner from "app/storybookComponents/WarningBanner/VisibilityBanner";
import CardWrapper from "app/components/CardWrapper/CardWrapper";

interface IAnalyticsProps {
  wrapAnalyticsInCard?: boolean;
}

export default function Analytics({
  wrapAnalyticsInCard,
}: Readonly<IAnalyticsProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ------------------ useStates ------------------
  const [showAgreementChart, setShowAgreementChart] = useState(false);
  const [showTeam360ResultsModal, setShowTeam360ResultsModal] =
    useState<null | TTeam360Factor>(null);
  const [showScoreBreakdownModal, setShowScoreBreakdownModal] = useState(false);
  const [showComparisonModal, setShowComparisonModal] = useState<
    "organization" | "department" | null
  >(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [
    whatSkillCanYouWorkOnDepartmentSelected,
    setWhatSkillCanYouWorkOnDepartmentSelected,
  ] = useState<null | number>(null);
  const [genderCardDepartmentSelected, setGenderCardDepartmentSelected] =
    useState<null | number>(null);
  const [showDateRangePickerModal, setShowDateRangePickerModal] =
    useState(false);
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState(false);
  const isSample = searchParams.get("isSample") === "true";

  // ------------------ Redux Selectors ------------------
  const sampleTeams = useAppSelector(selectSampleTeamsByTeamId);
  const teamsByTeamId = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const getTeamScoreStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const getGenderInsightsStatus = useAppSelector(selectGetGenderInsightsStatus);
  const companySettings = useAppSelector(selectCompanySettings);
  const getAnalyticsTextStatus = useAppSelector(selectGetAnalyticsTextStatus);
  const getSampleAnalyticsTextStatus = useAppSelector(
    selectGetSampleAnalyticsTextStatus
  );
  const companyAnalyticsText = useAppSelector(selectAnalyticsText);
  const sampleAnalyticsText = useAppSelector(selectSampleAnalyticsText);
  const analyticsText = isSample ? sampleAnalyticsText : companyAnalyticsText;
  const companyInfo = useAppSelector(selectCompanyInfo);
  const genderInsights = useAppSelector(selectGenderInsights);
  const textSynthesis = useAppSelector(selectTextSynthesis);
  const getTextSynthesisStatus = useAppSelector(selectGetTextSynthesisStatus);
  const departmentGenderInsights = useAppSelector(
    selectGenderInsightsByDepartmentId(genderCardDepartmentSelected)
  );
  const getOrganizationCommentsStatus = useAppSelector(
    selectGetOrganizationCommentsStatus
  );
  const organizationComments = useAppSelector(selectOrganizationComments);
  const getSampleOrganizationCommentsStatus = useAppSelector(
    selectGetSampleOrganizationCommentsStatus
  );
  const sampleOrganizationComments = useAppSelector(
    selectSampleOrganizationComments
  );
  const updatingTeam360DateRangeStatus = useAppSelector(
    selectUpdateTeam360DateRangeStatus
  );
  const organizationTeamAssessmentInstances = useAppSelector(
    selectOrganizationTeamAssessmentInstances
  );
  const team360CardsExpanded = useAppSelector(selectTeam360CardsExpanded);
  const team360IntervalPicked = useAppSelector(selectTeam360InstancePicked);
  const team360Scores = useAppSelector(
    selectAllTeam360Results(team360IntervalPicked ?? undefined)
  );
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const getTeamAssessmentInstancesStatus = useAppSelector(
    selectGetTeamAssessmentInstancesStatus
  );

  // ------------------ Derived States ------------------

  const companyScores = isSample
    ? team360Scores?.sampleCompanyScores
    : team360Scores?.companyScores;
  const departmentScores = isSample
    ? team360Scores?.sampleDepartmentScores
    : team360Scores?.departmentScores;

  // --------------------- Hooks ---------------------
  useEffect(() => {
    if (getTeamScoreStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [dispatch, getTeamScoreStatus]);

  useEffect(() => {
    if (getSampleAnalyticsTextStatus === "idle") {
      dispatch(getSampleAnalyticsTextAction());
    }
  }, [dispatch, getSampleAnalyticsTextStatus]);

  useEffect(() => {
    if (getAnalyticsTextStatus === "idle") {
      dispatch(getAnalyticsTextAction());
    }
  }, [dispatch, getAnalyticsTextStatus]);

  useEffect(() => {
    if (getOrganizationCommentsStatus === "idle") {
      dispatch(getOrganizationComments());
    }
  }, [dispatch, getOrganizationCommentsStatus]);

  useEffect(() => {
    if (isSample && getSampleOrganizationCommentsStatus === "idle") {
      dispatch(getSampleOrganizationComments());
    }
  }, [dispatch, getSampleOrganizationCommentsStatus, isSample]);

  useEffect(() => {
    if (getTeamAssessmentInstancesStatus === "idle") {
      dispatch(getTeamAssessmentInstances());
    }
  }, [dispatch, getTeamAssessmentInstancesStatus]);

  useLayoutEffect(() => {
    // -------showScoreBreakdownModal-------
    const showScoreBreakdownModalBool = searchParams.get(
      "showScoreBreakdownModal"
    );
    setShowScoreBreakdownModal(showScoreBreakdownModalBool === "true");
  }, [searchParams]);

  useEffect(() => {
    if (genderCardDepartmentSelected && !departmentGenderInsights) {
      dispatch(
        getDepartmentGenderInsights({
          departmentId: genderCardDepartmentSelected,
        })
      );
    }
  }, [genderCardDepartmentSelected, dispatch, departmentGenderInsights]);

  useEffect(() => {
    if (getGenderInsightsStatus !== "idle" || !companyInfo?.companyAccountId) {
      return;
    }
    if (isSample) {
      dispatch(getGenderInsights({ companyAccountId: 0 }));
      return;
    }
    dispatch(
      getGenderInsights({ companyAccountId: companyInfo?.companyAccountId })
    );
  }, [
    dispatch,
    getGenderInsightsStatus,
    companyInfo?.companyAccountId,
    isSample,
  ]);

  useEffect(() => {
    // Upon receiving a successful update of the date range we close the modal.
    if (updatingTeam360DateRangeStatus === "succeeded") {
      setShowDateRangePickerModal(false);
      dispatch(resetUpdateTeam360DateRangeStatus());
    }
  }, [updatingTeam360DateRangeStatus, dispatch]);

  const getMostRecentDate = (): string => {
    if (analyticsText?.overview?.mostRecentResultsDate) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      } as const;
      const date = new Date(analyticsText?.overview?.mostRecentResultsDate);
      const verbalDate = date.toLocaleDateString(undefined, options);
      return `Most recent results from ${verbalDate}`;
    }
    return "";
  };

  const getDropdown = (
    selectedDropdown: null | number,
    setState: (newState: null | number) => void
  ) => {
    const onDropdownSelect = (eventKey: string | null) => {
      setState(eventKey ? parseInt(eventKey) : null);
    };
    const dropdownItems: ReactNode[] = [];

    Object.values(departments).forEach((department) => {
      if (!departmentScores?.[department.departmentId]?.overall) {
        return;
      }
      dropdownItems.push(
        <Dropdown.Item
          key={department.departmentId}
          eventKey={department.departmentId}
        >
          {department.name}
        </Dropdown.Item>
      );
      dropdownItems.push(
        <Dropdown.Divider key={`${department.departmentId}-divider`} />
      );
    });
    dropdownItems.pop();

    return (
      <Dropdown onSelect={onDropdownSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {selectedDropdown
            ? departments[selectedDropdown]?.name
            : "This Organization"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <Dropdown.Item>This Organization</Dropdown.Item>
          {dropdownItems.length > 0 && <Dropdown.Divider />}
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const isLatestInstance = useMemo(() => {
    if (!team360IntervalPicked) return true;
    if (!team360IntervalPicked.endDate && !team360IntervalPicked.startDate) {
      return true;
    }

    const totalInstances = organizationTeamAssessmentInstances?.length ?? 0;

    return !!(
      totalInstances &&
      organizationTeamAssessmentInstances?.[0]?.endDate ===
        team360IntervalPicked?.endDate &&
      organizationTeamAssessmentInstances?.[0]?.startDate ===
        team360IntervalPicked?.startDate
    );
  }, [organizationTeamAssessmentInstances, team360IntervalPicked]);

  const getDepartmentsCard = () => {
    if (isDepartmentsHidden) {
      return null;
    }

    const departmentCardTitle = "How Do Your Departments Compare?";
    if (Object.keys(departments).length === 0) {
      return (
        <Card
          style={{
            padding: "20px",
          }}
          className="analytics-summary-card"
        >
          <h2>{departmentCardTitle}</h2>
          <div className="empty-card">
            <span>No Department Results</span>
            <p style={{ maxWidth: "none" }}>
              Set up departments in your organization to see results based on
              departments.
            </p>
            <div className="action-buttons">
              <Button
                onClick={() => {
                  // Navigate to the department tab
                }}
              >
                Set up departments
              </Button>
            </div>
          </div>
        </Card>
      );
    }

    return (
      <DepartmentSummaryCard
        snapShotText={analyticsText?.departmentSummary?.dimensionVarianceText}
        classificationArray={
          analyticsText?.departmentSummary?.classificationArray
        }
        cardDescriptions={
          analyticsText?.departmentSummary?.departmentSummaryText
            ? [analyticsText?.departmentSummary?.departmentSummaryText]
            : []
        }
        lowestScoringDepartment={
          analyticsText?.departmentSummary?.lowestScoringDepartment
        }
        highestScoringDepartment={
          analyticsText?.departmentSummary?.highestScoringDepartment
        }
        onSeeComparisonReport={() => {
          setShowComparisonModal("department");
        }}
        title={departmentCardTitle}
        isExpanded={team360CardsExpanded?.["DepartmentSummaryCard"]}
        setIsExpanded={(value) =>
          dispatch(
            setTeam360CardsExpanded({
              cardTitle: "DepartmentSummaryCard",
              value,
            })
          )
        }
      />
    );
  };

  const getResultsOverviewFooter = () => {
    const onSeeScoreBreakdownClick = () => {
      setSearchParams((searchParams) => {
        searchParams.set("showScoreBreakdownModal", "true");
        return searchParams;
      });
      setShowScoreBreakdownModal(true);
    };

    return (
      <>
        <Button
          onClick={() => {
            dispatch(setIsQuickActionDrawerOpen(false));
            setIsChecklistDrawerOpen(true);
          }}
        >
          See discussion checklist
        </Button>
        <Button variant="secondary-blue" onClick={onSeeScoreBreakdownClick}>
          See score breakdown
        </Button>
      </>
    );
  };

  const onCoachBotClick = () => {
    const {
      coachBotIntro,
      preSelectedPrompts: userPrompts,
      chatType,
    } = ORG_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE;

    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt({
        userPrompts,
        coachBotIntro,
        chatType,
        chatTypeId: companyInfo?.companyAccountId,
        type: "teamScan",
        report: analyticsText,
        comments: isSample ? sampleOrganizationComments : organizationComments,
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  const getFooterWithCoachBot = () => (
    <div className="analytics-sticky-footer">
      <div className="d-flex align-items-center">
        <div>
          <p className="small-body-text">{getMostRecentDate()}</p>
        </div>
        <div>
          <button className="no-style-button">
            <FontAwesomeIcon
              icon={["far", "gear"]}
              onClick={() => {
                setShowDateRangePickerModal(true);
              }}
            />
          </button>
        </div>
        <div>
          <Button
            variant="secondary-blue"
            onClick={() => {
              navigate("/AdvancedAnalytics");
            }}
            className="white-button"
          >
            Advanced Analytics <FontAwesomeIcon icon="arrow-right" />
          </Button>
        </div>
      </div>
      <div>
        <CustomChatbotButton
          onCoachbotClick={onCoachBotClick}
          text="Want help with these results?"
          className="assistant-bubble floating"
          includeButton
        />
      </div>
    </div>
  );
  const getFooterWithoutCoachBot = () => (
    <div className="analytics-sticky-footer">
      <div className="row-gap-12px">
        <Button
          onClick={() => {
            setShowDateRangePickerModal(true);
          }}
        >
          Configure results
        </Button>
        <Button
          variant="secondary-blue"
          onClick={() => {
            navigate("/AdvancedAnalytics");
          }}
          className="white-button"
        >
          Advanced Analytics <FontAwesomeIcon icon="arrow-right" />
        </Button>
      </div>
      <div>
        <p className="small-body-text">{getMostRecentDate()}</p>
      </div>
    </div>
  );

  const getStickyFooter = () => {
    if (isSample && !companySettings?.aiFeaturesEnabled) {
      return null;
    }

    return companySettings?.aiFeaturesEnabled
      ? getFooterWithCoachBot()
      : getFooterWithoutCoachBot();
  };

  const getAnalyticsBody = () => {
    if (
      getAnalyticsTextStatus === "loading" ||
      (!analyticsText?.overview && isSample)
    ) {
      return <Loading />;
    }

    const instanceBanner = (
      <InstanceBanner
        onConfigureResults={() => {
          setShowDateRangePickerModal(true);
        }}
        isLatestInstance={isLatestInstance}
        instanceShowing={team360IntervalPicked}
        hasEnoughResults={!!analyticsText?.overview}
        entity="organization"
      />
    );

    if (!analyticsText?.overview) {
      return (
        <EmptyTeam360ResultsCard
          completionInfo={analyticsText?.completionInfo}
          entityType="organization"
          hasEditAccess
        />
      );
    }

    const filteredTeams = isSample ? sampleTeams : teamsByTeamId;
    return (
      <div className="column-gap" style={{ gap: "40px" }}>
        {!isSample && instanceBanner}
        {analyticsText?.overview && !isSample ? (
          <VisibilityBanner
            style={{
              marginBottom: "0px",
              marginTop: "-12px",
            }}
          >
            <p>This report is only visible to admins.</p>
          </VisibilityBanner>
        ) : null}

        <CardWrapper hideCard={!wrapAnalyticsInCard}>
          <div className="team-360-full-page-card__section">
            <InsightReportHeader
              completionInfo={analyticsText?.completionInfo}
            />
            <ResultsOverviewCard
              overviewText={analyticsText.overview.overviewText}
              companyScores={companyScores}
              setShowTeam360ResultsModal={setShowTeam360ResultsModal}
              type="organization"
              title="How Is Your Organization Doing Overall?"
              footerButtons={getResultsOverviewFooter()}
              hideActionButtons
            />
          </div>
          <div className="team-360-full-page-card__section">
            <h2 className="team-360-divider-title">What Can You Do Next?</h2>
            <WhatSkillsCanYourTeamWorkOn
              team360Scores={
                whatSkillCanYouWorkOnDepartmentSelected
                  ? departmentScores?.[whatSkillCanYouWorkOnDepartmentSelected]
                  : companyScores
              }
              title="What Skills Can Your Organization Work On?"
              secondDropdown={
                !isDepartmentsHidden
                  ? getDropdown(
                      whatSkillCanYouWorkOnDepartmentSelected,
                      setWhatSkillCanYouWorkOnDepartmentSelected
                    )
                  : undefined
              }
              isExpanded={team360CardsExpanded?.["SkillsCard"]}
              setIsExpanded={(value) =>
                dispatch(
                  setTeam360CardsExpanded({
                    cardTitle: "SkillsCard",
                    value,
                  })
                )
              }
            />
          </div>
          <div className="team-360-full-page-card__section">
            <h2 className="team-360-divider-title">More Insights</h2>
            {getDepartmentsCard()}
            <TeamSummaryCard
              teamSummary={analyticsText.teamSummary}
              teams={filteredTeams}
              title="How Are Teams In Your Organization Doing?"
              onSeeComparisonReport={() => {
                setShowComparisonModal("organization");
              }}
              isExpanded={team360CardsExpanded?.["TeamSummaryCard"]}
              setIsExpanded={(value) =>
                dispatch(
                  setTeam360CardsExpanded({
                    cardTitle: "TeamSummaryCard",
                    value,
                  })
                )
              }
            />
            <ManagersVsTeamMembers
              varianceReport={analyticsText.varianceReport}
              title="How Do Team Leaders Compare With Team Members?"
              onSeeAgreementReport={() => {
                setShowAgreementChart(true);
              }}
              memberScores={analyticsText?.teamMemberScores}
              teamLeaderScores={analyticsText?.teamLeaderScores}
              isExpanded={team360CardsExpanded?.["ManagersVsTeamMembers"]}
              setIsExpanded={(value) =>
                dispatch(
                  setTeam360CardsExpanded({
                    cardTitle: "ManagersVsTeamMembers",
                    value,
                  })
                )
              }
            />

            <GenderInsightsCard
              genderInsights={
                genderCardDepartmentSelected
                  ? departmentGenderInsights
                  : genderInsights
              }
              title="Does Gender Impact Teamwork?"
              secondDropdown={getDropdown(
                genderCardDepartmentSelected,
                setGenderCardDepartmentSelected
              )}
              isCardCollapseByDefault
            />
            <TeamReflectionCard
              teamReflectionSummary={analyticsText.teamReflectionSummary}
              entityType="organization"
              isCollapseByDefault
              title="What Else Did Your Organization Say?"
            />
            <FreeTextSummary />
          </div>
        </CardWrapper>
        {getStickyFooter()}
      </div>
    );
  };

  return (
    <div className="analytics">
      {showTeam360ResultsModal && team360Scores ? (
        <Team360ResultsModal
          show={!!showTeam360ResultsModal}
          hideModal={() => setShowTeam360ResultsModal(null)}
          companyScores={companyScores}
          activeFactor={showTeam360ResultsModal}
        />
      ) : null}
      <Team360CommentsByYourTeamModal
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
        snapShotText={
          textSynthesis ? `Synthesis: ${textSynthesis.themeText}` : null
        }
        isSnapShotTextLoading={getTextSynthesisStatus === "loading"}
        teamComments={
          isSample ? sampleOrganizationComments : organizationComments
        }
      />
      <Team360ScoreBreakdownModal
        show={showScoreBreakdownModal}
        onHide={() => {
          setShowScoreBreakdownModal(false);
          navigate(-2);
        }}
        modalSearchParam="showScoreBreakdownModal"
        instance={team360IntervalPicked?.instance}
        startDate={team360IntervalPicked?.startDate}
        endDate={team360IntervalPicked?.endDate}
      />
      <Team360ComparisonModal
        show={!!showComparisonModal}
        onHide={() => setShowComparisonModal(null)}
        preSelectedDepartmentOrOrg={showComparisonModal ?? undefined}
        percentileScores={analyticsText?.teamSummary?.percentiles}
      />
      <Team360AgreementReportModal
        show={showAgreementChart}
        onHide={() => setShowAgreementChart(false)}
        usersScore={analyticsText?.teamMemberScores}
        comparedUserScore={analyticsText?.teamLeaderScores}
        userCustomLabel="Average of team members"
        comparedUserCustomLabel="Average of team leaders"
        comparedUserAvatar={
          <div style={{ marginTop: "4px" }}>
            <FontAwesomeIcon icon="diamond" />
          </div>
        }
        differenceThreshold={8}
      />
      <DateRangePicker
        isLoading={updatingTeam360DateRangeStatus === "loading"}
        failedToUpdate={updatingTeam360DateRangeStatus === "failed"}
        onResetFailedToUpdate={() => {
          dispatch(resetUpdateTeam360DateRangeStatus());
        }}
        onHide={() => setShowDateRangePickerModal(false)}
        show={showDateRangePickerModal}
        onSubmit={({ startDate, endDate, instanceType, instance }) => {
          dispatch(
            updateTeam360DateRange({
              startDate,
              endDate,
              instanceType,
              instance,
              throwErrorIfNoOverview: true,
            })
          );
        }}
        availableInstances={organizationTeamAssessmentInstances}
        entity="organization"
      />
      {isSample && <SampleBanner entity="organization" />}
      {getAnalyticsBody()}

      <TeamScanActionChecklistDrawer
        isOpen={isChecklistDrawerOpen}
        setIsOpen={(incomingBool) => setIsChecklistDrawerOpen(incomingBool)}
        onSeeCommentsClick={() => setShowCommentModal(true)}
        isLeader
        type="Organization"
      />
    </div>
  );
}
