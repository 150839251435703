import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import {
  Team,
  CompanyInfo,
  UserInfo,
  CheckInviteCSVResponse,
  TeamMember,
  UserUpdatePayload,
  AdditionalUserSettings,
} from "./types";
import {
  getDepartments,
  getDepartmentTypes,
  addUserLeader,
  removeUserLeader,
  getCompanySettings,
  toggleDisableDepartments,
  getTeamAndDepartmentLeads,
} from "app/containers/AdminConsole/slice";
import { getCompanyGuide } from "app/containers/CompanyGuide/slice";
import {
  getUserOnboardingTracking,
  onTrackUserEvent,
} from "app/components/Onboarding/slice";
import {
  getNotifications,
  onCreateTeamDismissNotification,
  onInviteMembersToTeamDismissNotification,
} from "app/components/Notifications/slice";
import { addInvitedLeader } from "../Dashboard/slice";
import { setCreatedTeamId } from "app/components/Modals/slice";
import {
  updateUserAbleToCreateTeams,
  updateUserAbleToInviteUsers,
} from "../UserGuide/slice";
import { CompanySettings } from "../AdminConsole/types";

// ------------------------------------ State Type/Structure ------------------------------------
export interface GlobalState {
  godMode: boolean; // This is used when the user is logged in via intranet to prevent appCues modals from showing
  currentUserAccountId: number | null;
  companyInfo: CompanyInfo | null;
  teamsByTeamId: { [teamId: number]: Team };
  sampleTeamsByTeamId: { [teamId: number]: Team };
  usersInfoById: { [userAccountId: string | number]: UserInfo };
  sampleUsersInfoById: { [userAccountId: string | number]: UserInfo };
  teamInviteLinks: { [teamId: number]: string };
  createdTeamId: null | number;
  invalidInvitedStrings: string[];
  invitedCount?: number;
  refreshingLoggedInUserStatus: responseStatus;
  appCuesInformation: { [key: string]: string };
  configCatFlags: { [key: string]: boolean };
  getConfigCatFlagsStatus: responseStatus;
  createdButNotInvitedUsersIds: number[];
  inviteUsersViaCSVStatus: responseStatus;
  getUserStatus: responseStatus; // This status is specifically for the logged in user
  getUserInfoByIdStatus: responseStatus;
  createTeamStatus: responseStatus;
  deleteTeamStatus: responseStatus;
  getCompanyInfoStatus: responseStatus;
  updateUserInfoStatus: responseStatus;
  updateUserByIdStatus: responseStatus;
  updateTeamStatus: responseStatus;
  getAllUsersStatus: responseStatus;
  inviteUserByEmailStatus: responseStatus;
  getTeamInviteLinkStatus: responseStatus;
  getTeamByTeamIdStatus: responseStatus;
  getAllTeamsByCompanyAccountIdStatus: responseStatus;
  removeTeamMemberFromTeamStatus: responseStatus;
  userDeletedStatus: responseStatus;
  updateTeamMemberByTeamMemberIdStatus: responseStatus;
  getAppCuesInformationStatus: responseStatus;
}

// ------------------------------------ InitialState ------------------------------------
const initialState: GlobalState = {
  godMode: false,
  currentUserAccountId: null,
  companyInfo: null,
  teamInviteLinks: {},
  usersInfoById: {},
  sampleUsersInfoById: {},
  teamsByTeamId: {},
  sampleTeamsByTeamId: {},
  createdTeamId: null,
  invitedCount: undefined,
  appCuesInformation: {},
  invalidInvitedStrings: [],
  configCatFlags: {},
  getConfigCatFlagsStatus: "idle",
  createdButNotInvitedUsersIds: [],
  inviteUsersViaCSVStatus: "idle",
  getUserStatus: "idle",
  deleteTeamStatus: "idle",
  createTeamStatus: "idle",
  getCompanyInfoStatus: "idle",
  updateUserInfoStatus: "idle",
  updateUserByIdStatus: "idle",
  updateTeamStatus: "idle",
  getAllUsersStatus: "idle",
  inviteUserByEmailStatus: "idle",
  getTeamInviteLinkStatus: "idle",
  getTeamByTeamIdStatus: "idle",
  getAllTeamsByCompanyAccountIdStatus: "idle",
  removeTeamMemberFromTeamStatus: "idle",
  userDeletedStatus: "idle",
  updateTeamMemberByTeamMemberIdStatus: "idle",
  getUserInfoByIdStatus: "idle",
  refreshingLoggedInUserStatus: "idle",
  getAppCuesInformationStatus: "idle",
};

// ------------------------------------ GETS ------------------------------------
// IMPORTANT: This endpoint is only used for the logged in user
export const getUser = createAsyncThunk(
  "global/getUser",
  async (userAccountId: number | string, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/userInfo`;
    const getUserResponse = (await request(requestUrl)) as UserInfo &
      AdditionalUserSettings;

    // When we log the user in, we also need to make sure that that user has the newest ti flag enabled, if its not then we should throw an error
    const getHasFlagUrl = `${REACT_APP_API_URL}/talentInsights/companies/${getUserResponse.companyAccountId}/newTalentInsights`;
    const flagObject = (await request(getHasFlagUrl)) as {
      newTalentInsights: 0 | 1;
    };

    if (flagObject.newTalentInsights === 0) {
      throw Error("New Talent Insights is not enabled for this company.");
    }

    dispatch(
      updateUserAbleToCreateTeams(!!getUserResponse?.userIsAbleToCreateTeams)
    );
    dispatch(
      updateUserAbleToInviteUsers(!!getUserResponse?.userIsAbleToInviteUsers)
    );
    dispatch(toggleDisableDepartments(!!getUserResponse?.areDepartmentsHidden));

    // If the response was successful we make another api call to fetch the company account information
    dispatch(getCompanyInfo(getUserResponse.companyAccountId));
    dispatch(getCompanyGuide(getUserResponse.companyAccountId));
    dispatch(getCompanySettings(getUserResponse.companyAccountId));
    dispatch(getUserOnboardingTracking());
    // Fetch all of the teams that are accessible to the current user, but will not hold the actual team member data.
    dispatch(
      getAllTeamsByCompanyAccountId({
        companyAccountId: getUserResponse.companyAccountId,
      })
    );
    // Similar to the above call, but this will fetch all of the users that are accessible to the current user
    dispatch(getAllUsers());
    // Fetch all of the department types and departments
    dispatch(getDepartmentTypes());
    dispatch(getDepartments(getUserResponse.companyAccountId));
    // Fetch all of the team and department leads
    dispatch(getTeamAndDepartmentLeads());
    return { response: getUserResponse, userAccountId: Number(userAccountId) };
  },
  {
    condition: (_, { getState }) => {
      const {
        global: { getUserStatus },
      } = getState() as RootState;
      if (getUserStatus === "loading") {
        return false;
      }
    },
  }
);

export const refetchLoggedInUser = createAsyncThunk(
  "global/refetchLoggedInUser",
  async (userAccountId: number | string, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/userInfo`;
    const getUserResponse = (await request(requestUrl)) as UserInfo &
      AdditionalUserSettings;

    dispatch(
      updateUserAbleToCreateTeams(!!getUserResponse?.userIsAbleToCreateTeams)
    );
    dispatch(
      updateUserAbleToInviteUsers(!!getUserResponse?.userIsAbleToInviteUsers)
    );
  }
);

export const getTeamByTeamId = createAsyncThunk(
  "global/getTeamByTeamId",
  async (teamId: number, thunkAPI) => {
    const {
      global: { teamsByTeamId },
    } = thunkAPI.getState() as RootState;
    // If the team has already been searchedByTeamId no need to search for it again.
    if (teamsByTeamId[Number(teamId)]?.searchedByTeamId) {
      return { response: teamsByTeamId[Number(teamId)], teamId };
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}`;
    const getTeamByIdResponse = (await request(requestUrl)) as Team;

    // Might be okay to remove this call and only call it when we need to get the invite link... but we will see
    thunkAPI.dispatch(getTeamInviteLink({ teamId }));
    return { response: getTeamByIdResponse, teamId };
  }
);

export const getCompanyInfo = createAsyncThunk(
  "global/getCompanyInfo",
  async (companyId: number | string) => {
    const requestUrl = `${REACT_APP_API_URL}/teaming/company/${companyId}`;
    const getCompanyInfoResponse = (await request(requestUrl)) as CompanyInfo;
    return { ...getCompanyInfoResponse, companyAccountId: Number(companyId) };
  },
  {
    condition: (companyId, { getState }) => {
      const {
        global: { companyInfo, getCompanyInfoStatus },
      } = getState() as RootState;
      if (
        companyInfo?.companyAccountId === Number(companyId) || // This means that we already have the company info in our state
        getCompanyInfoStatus === "loading"
      ) {
        return false;
      }
    },
  }
);

export const getUserInfoById = createAsyncThunk(
  "global/getUserInfoById",
  async (userAccountId: number | string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/userInfo`;
    const getUserInfoByIdResponse = (await request(requestUrl)) as UserInfo;
    return { response: getUserInfoByIdResponse, userAccountId };
  },
  {
    condition: (userAccountId, { getState }) => {
      const {
        global: { usersInfoById, getUserInfoByIdStatus },
      } = getState() as RootState;
      if (usersInfoById[userAccountId] || getUserInfoByIdStatus === "loading") {
        return false;
      }
    },
  }
);

export const getAllTeamsByCompanyAccountId = createAsyncThunk(
  "global/getAllTeamsByCompanyAccountId",
  async ({
    companyAccountId,
  }: {
    companyAccountId: number;
    refreshTeams?: boolean;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/companies/${companyAccountId}/teams`;
    const getAllTeamsResponse = (await request(requestUrl)) as {
      [teamId: number]: Team;
    };
    return getAllTeamsResponse;
  },
  {
    condition: ({ refreshTeams }, { getState }) => {
      const {
        global: { getAllTeamsByCompanyAccountIdStatus },
      } = getState() as RootState;
      if (getAllTeamsByCompanyAccountIdStatus === "loading") {
        return false;
      }

      if (refreshTeams) {
        return true;
      }
    },
  }
);

export const getAllUsers = createAsyncThunk(
  "global/getAllUsers",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users`;
    const getAllUsersResponse = (await request(requestUrl)) as UserInfo[];
    return getAllUsersResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        global: { getAllUsersStatus },
      } = getState() as RootState;
      if (getAllUsersStatus === "loading") {
        return false;
      }
    },
  }
);

export const getAppCuesInformation = createAsyncThunk(
  "global/getAppCuesInformation",
  async (_, { getState }) => {
    const {
      global: { godMode },
    } = getState() as RootState;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/appCuesInformation`;
    const getAppCuesInformationResponse = (await request(requestUrl)) as {
      [key: string]: string;
    };
    return { ...getAppCuesInformationResponse, godMode: godMode ? "1" : "0" };
  }
);

// ------------------------------------ POSTS ------------------------------------
export const createTeam = createAsyncThunk(
  "global/createTeam",
  async (
    {
      payload,
      userAccountId,
    }: {
      payload: {
        teamName: string;
        departmentId: number;
        teamDescription: string;
        teamMemberEmails?: string[];
        teamLeaderEmail?: string;
        sendEmails?: boolean;
      };
      userAccountId: number;
    },
    { dispatch, getState }
  ) => {
    const {
      global: { usersInfoById },
    } = getState() as RootState;

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams`;
    const createTeamResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as Team;

    const uninvitedTeamLeaders: number[] = [];
    if (!payload.sendEmails) {
      createTeamResponse.teamMembers?.forEach((teamMember) => {
        uninvitedTeamLeaders.push(teamMember.userAccountId);
      });
    }

    // We will need to add the user as a team leader of the team that they just created.
    dispatch(
      addUserLeader({
        userAccountId,
        type: "teams",
        id: createTeamResponse.teamId,
      })
    );

    dispatch(getAllUsers());
    dispatch(getNotifications());
    dispatch(setCreatedTeamId(createTeamResponse.teamId));
    dispatch(onCreateTeamDismissNotification());

    const teamLeadUserAccountIds = [];

    if (
      usersInfoById[userAccountId]?.emailAddress === payload.teamLeaderEmail &&
      !createTeamResponse.teamLeadUserAccountIds?.includes(userAccountId)
    ) {
      teamLeadUserAccountIds.push(userAccountId);
    }

    return {
      response: {
        ...createTeamResponse,
        teamLeadUserAccountIds,
        uninvitedTeamLeaders,
      },
      userAccountId,
    };
  }
);

export const getTeamInviteLink = createAsyncThunk(
  "global/getTeamInviteLink",
  async ({
    teamId,
    expiration = 20,
  }: {
    teamId: number;
    expiration?: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/invitationLink`;
    const getTeamInviteLinkResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ expiration }),
    })) as {
      link: string;
    };
    return { response: getTeamInviteLinkResponse, teamId };
  }
);

export const previewInviteUsersViaCSV = createAsyncThunk(
  "global/previewInviteUsersViaCSV",
  async (
    payload: { firstName: string; lastName: string; emailAddress: string }[]
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/previewInviteUsersViaCSV`;
    const previewInviteUsersViaCSVResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ rows: payload }),
    })) as CheckInviteCSVResponse;

    return previewInviteUsersViaCSVResponse;
  }
);

export const inviteUsersViaCSV = createAsyncThunk(
  "global/inviteUsersViaCSV",
  async (
    payload: {
      rows: {
        firstName: string;
        lastName: string;
        emailAddress: string;
        jobTitle?: string;
        gender?: string;
      }[];
      teamId?: number;
      sendInvitations?: boolean;
      onSuccess?: () => void;
    },
    { dispatch }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/inviteUsersViaCSV`;
    const inviteUsersViaCSVResponse = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    // If payload.teamId is defined then we need to track that event as part of our tracked events
    if (payload.teamId) {
      dispatch(
        onTrackUserEvent({
          event: "invitedTeamMember",
          teamId: payload.teamId,
        })
      );
    }
    payload.onSuccess?.();
    return {
      response: inviteUsersViaCSVResponse,
      teamId: payload.teamId,
      sendInvitations: payload.sendInvitations,
    };
  }
);

export const inviteUserByEmail = createAsyncThunk(
  "global/inviteUserByEmail",
  async (
    payload: {
      emailAddresses: string[];
      teamId?: number;
      ti_onboardingRoleId?: 1 | 2 | 3 | 4; // 1 = Org Admin, 2 = Department Leader, 3 = Team Leader, 4 = Team Member,
      ti_onboardingDepartmentId?: number;
      sendInvitations?: boolean;
    },
    { dispatch, rejectWithValue, getState }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users`;
    const inviteUserByEmailResponse = (await request(
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
      true
    )) as {
      createdUsers: UserInfo[];
      skippedEmails: string[];
      createdTeamMemberships: TeamMember[];
      errors?: {
        [emailAddress: string]: string;
      };
      status: number;
    };

    if (
      inviteUserByEmailResponse.status === 400 ||
      inviteUserByEmailResponse.errors
    ) {
      return rejectWithValue(inviteUserByEmailResponse?.errors);
    }

    if (
      payload.ti_onboardingRoleId === 3 &&
      payload.ti_onboardingDepartmentId
    ) {
      inviteUserByEmailResponse?.createdUsers?.forEach((user) => {
        dispatch(
          addInvitedLeader({
            userAccountId: user.userAccountId,
            departmentId: payload.ti_onboardingDepartmentId!,
            emailAddress: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
          })
        );
      });
    }

    // If payload.teamId is defined then we need to track that event as part of our tracked events
    if (payload.teamId) {
      dispatch(
        onTrackUserEvent({
          event: "invitedTeamMember",
          teamId: payload.teamId,
        })
      );
      dispatch(onInviteMembersToTeamDismissNotification(payload.teamId));
      dispatch(getNotifications());
    }

    let createdUsers = inviteUserByEmailResponse.createdUsers || [];

    if (payload.sendInvitations) {
      createdUsers = createdUsers.map((user) => ({ ...user, tmgInvited: 1 }));
    }

    const state = getState() as RootState;

    const {
      adminConsole: { companySettings },
    } = state;
    const isAdmin = !!selectIsCurrentUserAdmin(state);

    return {
      ...inviteUserByEmailResponse,
      createdUsers,
      teamId: payload.teamId,
      sendInvitations: payload.sendInvitations,
      companySettings,
      isAdmin,
    };
  }
);

// ------------------------------------ PUTS ------------------------------------
export const updateUserInfo = createAsyncThunk(
  "global/updateUserInfo",
  async (
    payload: {
      linkedInUrl?: string;
      telephoneNumber?: string;
      secondaryEmail?: string;
      firstTeamsLogin?: string;
      hasCoachBoEmailsEnabled?: 0 | 1;
      hasCoachBoConversationHistoryEnabled?: 0 | 1;
    },
    thunkAPI
  ) => {
    const { global } = thunkAPI.getState() as RootState;
    if (global.currentUserAccountId === null) {
      throw Error();
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${global.currentUserAccountId}/userInfo`;

    const updateUserInfoResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as Partial<UserInfo>;

    return {
      response: updateUserInfoResponse,
      userAccountId: global.currentUserAccountId,
    };
  }
);

export const updateUserById = createAsyncThunk(
  "global/updateUserById",
  async ({
    userAccountId,
    payload,
  }: {
    userAccountId: number;
    payload: UserUpdatePayload;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}`;

    const updateUserInfoResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as UserUpdatePayload;

    return {
      response: updateUserInfoResponse,
      userAccountId,
    };
  }
);

export const updateTeam = createAsyncThunk(
  "global/updateTeam",
  async ({ payload, teamId }: { payload: Partial<Team>; teamId: number }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}`;
    const updateTeamResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as Team;

    return { response: updateTeamResponse };
  }
);

export const editTeamMemberFromTeam = createAsyncThunk(
  "global/editTeamMemberFromTeam",
  async (
    {
      payload,
      teamMemberId,
      userAccountId,
      teamId,
    }: {
      payload: { isTeamLead: 0 | 1 };
      userAccountId: number;
      teamMemberId: number;
      teamId: number;
    },
    { dispatch }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamMembers/${teamMemberId}`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    // TODO: Might move this endpoint call into the teamGuide container and create a action inside of the userGuide slice that will update the user.
    //     additionally might move all of the user data inside of the userGuide.
    if (payload.isTeamLead === 1) {
      dispatch(addUserLeader({ userAccountId, type: "teams", id: teamId }));
    } else {
      dispatch(removeUserLeader({ userAccountId, type: "teams", id: teamId }));
    }

    return { payload, teamMemberId, teamId };
  }
);

// ------------------------------------ DELETES ------------------------------------
export const deleteTeam = createAsyncThunk(
  "global/deleteTeam",
  async (teamId: number, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}`;
    await request(requestUrl, {
      method: "DELETE",
    });
    dispatch(getNotifications());
    return { teamId };
  }
);

export const removeTeamMemberFromTeam = createAsyncThunk(
  "global/removeTeamMemberFromTeam",
  async ({
    teamMemberId,
    teamId,
    userAccountId,
  }: {
    teamMemberId: number;
    teamId: number;
    userAccountId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamMembers/${teamMemberId}`;
    await request(requestUrl, {
      method: "DELETE",
    });
    return { teamMemberId, teamId, userAccountId };
  }
);

export const deleteUserByUserAccountId = createAsyncThunk(
  "global/deleteUserByUserAccountId",
  async (userAccountId: number, { getState }) => {
    const {
      global: { currentUserAccountId },
    } = getState() as RootState;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}`;

    const deleteUserByUserAccountIdResponse = await request(requestUrl, {
      method: "DELETE",
    });
    toast.success("Deleting User Successful!", {
      ...successNotificationOptions,
    });

    if (currentUserAccountId === userAccountId) {
      window.location.href = "/";
    }

    return {
      response: deleteUserByUserAccountIdResponse,
      userAccountId,
    };
  }
);

// This function is here just incase we would like to execute more api calls when the user signs in.
export const signUserIn =
  ({
    userAccountId,
    godMode,
  }: {
    userAccountId: number | string;
    godMode?: boolean;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(getUser(userAccountId));
    dispatch(setGodMode(!!godMode));
  };

// ------------------------------------ Beginning of Slice Definition ------------------------------------
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGodMode: (state, action: PayloadAction<boolean>) => {
      state.godMode = action.payload;
    },
    clearCurrentUserAccountId(state) {
      state.currentUserAccountId = null;
    },
    updateUsersAction(
      state,
      action: PayloadAction<{
        [userAccountId: number]: Partial<UserInfo>;
      }>
    ) {
      const usersInfoById = { ...state.usersInfoById };
      Object.entries(action.payload).forEach(([id, userInfo]) => {
        const userAccountId = Number(id);
        usersInfoById[userAccountId] = {
          ...state.usersInfoById[userAccountId],
          ...userInfo,
        };
      });
      return {
        ...state,
        usersInfoById,
      };
    },
    updateTeamsAction(
      state,
      action: PayloadAction<{
        [teamId: number]: Partial<Team>;
      }>
    ) {
      const teamsByTeamId = { ...state.teamsByTeamId };
      Object.entries(action.payload).forEach(([id, team]) => {
        const teamId = Number(id);
        teamsByTeamId[teamId] = {
          ...teamsByTeamId[teamId],
          ...team,
        };
      });
      return {
        ...state,
        teamsByTeamId,
      };
    },
    removeUsersFromCreatedButNotInvitedUsersIds(
      state,
      action: PayloadAction<number[]>
    ) {
      return {
        ...state,
        createdButNotInvitedUsersIds: state.createdButNotInvitedUsersIds.filter(
          (id) => !action.payload.includes(id)
        ),
      };
    },
    deleteUsersProfilePicture(
      state,
      { payload }: PayloadAction<{ userAccountId: number }>
    ) {
      return {
        ...state,
        usersInfoById: {
          ...state.usersInfoById,
          [payload.userAccountId]: {
            ...state.usersInfoById[payload.userAccountId],
            profilePicture: undefined,
          },
        },
      };
    },
    updateUsersProfilePicture(
      state,
      {
        payload: { profilePicture, profilePictureFileName, userAccountId },
      }: PayloadAction<{
        profilePicture: string;
        profilePictureFileName: string;
        userAccountId: number;
      }>
    ) {
      return {
        ...state,
        usersInfoById: {
          ...state.usersInfoById,
          [userAccountId]: {
            ...state.usersInfoById[userAccountId],
            profilePicture,
            profilePictureFileName,
          },
        },
      };
    },
    updateUserImage(
      state,
      {
        payload: { coverPhoto, profilePicture, userAccountId },
      }: PayloadAction<{
        profilePicture: string;
        coverPhoto: string;
        userAccountId: number;
      }>
    ) {
      return {
        ...state,
        usersInfoById: {
          ...state.usersInfoById,
          [userAccountId]: {
            ...state.usersInfoById[userAccountId],
            profilePicture,
            coverPhoto,
          },
        },
      };
    },
    updateUserInfoById(
      state,
      {
        payload,
      }: PayloadAction<{
        userAccountId: number;
        updatedUserInfo: Partial<UserInfo>;
      }>
    ) {
      if (!state.usersInfoById[payload.userAccountId]) {
        return state;
      }
      return {
        ...state,
        usersInfoById: {
          ...state.usersInfoById,
          [payload.userAccountId]: {
            ...state.usersInfoById[payload.userAccountId],
            ...payload.updatedUserInfo,
          },
        },
      };
    },
    updateTeamImage(
      state,
      {
        payload: { coverPhoto, teamId, profilePicture },
      }: PayloadAction<{
        coverPhoto: string;
        teamId: number;
        profilePicture: string;
      }>
    ) {
      return {
        ...state,
        teamsByTeamId: {
          ...state.teamsByTeamId,
          [teamId]: {
            ...state.teamsByTeamId[teamId],
            profilePicture,
            coverPhoto,
          },
        },
      };
    },
    resetCreateTeamStatus(state) {
      state.createTeamStatus = "idle";
    },
    resetDeleteTeamStatus(state) {
      state.deleteTeamStatus = "idle";
    },
    clearInviteCount(state) {
      state.invitedCount = undefined;
    },
    resetUserDeletedState(state) {
      state.userDeletedStatus = "idle";
    },
    resetUpdateUpdateUserInfoStatus(state) {
      state.updateUserInfoStatus = "idle";
    },
    removeTeamIdFromUserAccountId(
      state,
      {
        payload,
      }: PayloadAction<{ teamId: number; userAccountId: number | null }>
    ) {
      if (
        !payload.userAccountId ||
        !state.usersInfoById[payload.userAccountId]
      ) {
        return state;
      }
      return {
        ...state,
        usersInfoById: {
          ...state.usersInfoById,
          [payload.userAccountId]: {
            ...state.usersInfoById[payload.userAccountId],
            teamIds: state.usersInfoById[payload.userAccountId].teamIds.filter(
              (teamId) => teamId !== payload.teamId
            ),
          },
        },
      };
    },
    resetInvalidInvitedStrings(state) {
      state.invalidInvitedStrings = [];
    },
    acceptTeamInvitation(
      state,
      { payload: { teamId } }: PayloadAction<{ teamId: number }>
    ) {
      const { currentUserAccountId } = state;
      if (!currentUserAccountId || !state.teamsByTeamId[teamId]) {
        return state;
      }
      return {
        ...state,
        teamsByTeamId: {
          ...state.teamsByTeamId,
          [teamId]: {
            ...state.teamsByTeamId[teamId],
            pendingTeamMemberIds: state.teamsByTeamId[
              teamId
            ].pendingTeamMemberIds?.filter((id) => id !== currentUserAccountId),
          },
        },
      };
    },
    declineTeamInvitation(
      state,
      { payload: { teamId } }: PayloadAction<{ teamId: number }>
    ) {
      const { currentUserAccountId } = state;
      const updatedTeam = state.teamsByTeamId[teamId];
      if (!currentUserAccountId || !updatedTeam) {
        return state;
      }
      updatedTeam.pendingTeamMemberIds =
        updatedTeam.pendingTeamMemberIds?.filter(
          (id) => id !== currentUserAccountId
        );
      updatedTeam.teamMemberIds = updatedTeam.teamMemberIds?.filter(
        (id) => id !== currentUserAccountId
      );
      return {
        ...state,
        teamsByTeamId: {
          ...state.teamsByTeamId,
          [teamId]: updatedTeam,
        },
      };
    },
    setConfigCatFlag(
      state,
      { payload }: PayloadAction<{ key: string; value: boolean }>
    ) {
      return {
        ...state,
        configCatFlags: {
          ...state.configCatFlags,
          [payload.key]: payload.value,
        },
      };
    },
    setConfigCatFlagStatus(state, { payload }: PayloadAction<responseStatus>) {
      state.getConfigCatFlagsStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.getUserStatus = "loading";
      })
      .addCase(
        getUser.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{ response: UserInfo; userAccountId: number }>
        ) => {
          return {
            ...state,
            currentUserAccountId: userAccountId,
            getUserStatus: "succeeded",
            usersInfoById: {
              ...state.usersInfoById,
              [userAccountId]: {
                ...state.usersInfoById[userAccountId],
                ...response,
              },
            },
          };
        }
      )
      .addCase(getUser.rejected, (state) => {
        state.getUserStatus = "failed";
      })
      .addCase(getAllUsers.pending, (state) => {
        state.getAllUsersStatus = "loading";
      })
      .addCase(
        getAllUsers.fulfilled,
        (state, { payload }: PayloadAction<UserInfo[]>) => {
          const usersInfoById = Object.assign({}, state.usersInfoById);
          const sampleUsersInfoById: {
            [userAccountId: string]: UserInfo;
          } = {};
          const createdButNotInvitedUsersIds: number[] = [];

          payload.forEach((userInfo) => {
            if (userInfo.companyAccountId === 0) {
              sampleUsersInfoById[userInfo.userAccountId] = userInfo;
              return;
            }
            if (userInfo.tmgInvited === 0 && !userInfo.firstTeamsLogin) {
              createdButNotInvitedUsersIds.push(userInfo.userAccountId);
            }
            usersInfoById[userInfo.userAccountId] = {
              ...state.usersInfoById[userInfo.userAccountId],
              ...userInfo,
            };
          });

          return {
            ...state,
            getAllUsersStatus: "succeeded",
            usersInfoById,
            sampleUsersInfoById,
            createdButNotInvitedUsersIds,
          };
        }
      )
      .addCase(getAllUsers.rejected, (state) => {
        state.getAllUsersStatus = "failed";
      })
      .addCase(getCompanyInfo.pending, (state) => {
        state.getCompanyInfoStatus = "loading";
      })
      .addCase(
        getCompanyInfo.fulfilled,
        (state, action: PayloadAction<CompanyInfo>) => {
          state.companyInfo = action.payload;
          state.getCompanyInfoStatus = "succeeded";
        }
      )
      .addCase(getCompanyInfo.rejected, (state) => {
        state.getCompanyInfoStatus = "failed";
      })
      .addCase(getUserInfoById.pending, (state) => {
        state.getUserInfoByIdStatus = "loading";
      })
      .addCase(
        getUserInfoById.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: UserInfo;
            userAccountId: number | string;
          }>
        ) => {
          return {
            ...state,
            getUserInfoByIdStatus: "succeeded",
            usersInfoById: {
              ...state.usersInfoById,
              [userAccountId]: {
                ...state.usersInfoById[userAccountId],
                ...response,
              },
            },
          };
        }
      )
      .addCase(getUserInfoById.rejected, (state) => {
        state.getUserInfoByIdStatus = "failed";
      })
      .addCase(updateUserInfo.pending, (state) => {
        state.updateUserInfoStatus = "loading";
      })
      .addCase(
        updateUserInfo.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: Partial<UserInfo>;
            userAccountId: number;
          }>
        ) => {
          const userSettingsOrUserInfo =
            "hasCoachBoConversationHistoryEnabled" in response ||
            "hasCoachBoEmailsEnabled" in response
              ? "User Settings"
              : "User Info";

          toast.success(`Updating ${userSettingsOrUserInfo} Successfully!`, {
            ...successNotificationOptions,
          });
          // We will be removing the profile picture and cover photo from response object because it is sending back the string name of the file and not the actual file.
          delete response.profilePicture;
          delete response.coverPhoto;
          const updatedUser = {
            ...state.usersInfoById[userAccountId],
            ...response,
          };
          return {
            ...state,
            updateUserInfoStatus: "succeeded",
            usersInfoById: {
              ...state.usersInfoById,
              [userAccountId]: updatedUser,
            },
          };
        }
      )
      .addCase(updateUserInfo.rejected, (state) => {
        state.updateUserInfoStatus = "failed";
      })
      .addCase(updateUserById.pending, (state) => {
        state.updateUserByIdStatus = "loading";
      })
      .addCase(
        updateUserById.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: UserUpdatePayload;
            userAccountId: number;
          }>
        ) => {
          toast.success("Updating User Info Successfully!", {
            ...successNotificationOptions,
          });
          const updatedUser = {
            ...state.usersInfoById[userAccountId],
            ...response,
          };
          return {
            ...state,
            updateUserByIdStatus: "succeeded",
            usersInfoById: {
              ...state.usersInfoById,
              [userAccountId]: updatedUser,
            },
          };
        }
      )
      .addCase(updateUserById.rejected, (state) => {
        state.updateUserByIdStatus = "failed";
      })
      .addCase(updateTeam.pending, (state) => {
        state.updateTeamStatus = "loading";
      })
      .addCase(
        updateTeam.fulfilled,
        (
          state,
          {
            payload: { response },
          }: PayloadAction<{
            response: Team;
          }>
        ) => {
          toast.success("Team update succeeded!", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updateTeamStatus: "succeeded",
            teamsByTeamId: {
              ...state.teamsByTeamId,
              [response.teamId]: {
                ...state.teamsByTeamId[response.teamId],
                ...response,
              },
            },
          };
        }
      )
      .addCase(updateTeam.rejected, (state) => {
        state.updateTeamStatus = "failed";
      })
      .addCase(createTeam.pending, (state) => {
        state.createTeamStatus = "loading";
      })
      .addCase(
        createTeam.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: Team;
            userAccountId: number;
          }>
        ) => {
          toast.success("Creating Team Successful!", {
            ...successNotificationOptions,
          });

          return {
            ...state,
            teamsByTeamId: {
              ...state.teamsByTeamId,
              [response.teamId]: {
                ...response,
              },
            },
            usersInfoById: {
              ...state.usersInfoById,
              [userAccountId]: {
                ...state.usersInfoById[userAccountId],
                teamIds: [
                  ...state.usersInfoById[userAccountId].teamIds,
                  response.teamId,
                ],
              },
            },
            createTeamStatus: "succeeded",
          };
        }
      )
      .addCase(createTeam.rejected, (state) => {
        state.createTeamStatus = "failed";
      })
      .addCase(deleteTeam.pending, (state) => {
        state.deleteTeamStatus = "loading";
      })
      .addCase(
        deleteTeam.fulfilled,
        (
          state: GlobalState,
          { payload: { teamId } }: PayloadAction<{ teamId: number }>
        ) => {
          toast.success("Deleting Team Successful!", {
            ...successNotificationOptions,
          });
          const teamsByTeamId = { ...state.teamsByTeamId };
          delete teamsByTeamId[teamId];

          return {
            ...state,
            deleteTeamStatus: "succeeded",
            teamsByTeamId,
          };
        }
      )
      .addCase(deleteTeam.rejected, (state) => {
        state.deleteTeamStatus = "failed";
      })
      .addCase(inviteUsersViaCSV.pending, (state) => {
        state.inviteUsersViaCSVStatus = "loading";
      })
      .addCase(
        inviteUsersViaCSV.fulfilled,
        (state, { payload }: PayloadAction<{ sendInvitations?: boolean }>) => {
          toast.success(
            `${
              payload.sendInvitations ? "Inviting" : "Adding"
            } Users Successful!`,
            {
              ...successNotificationOptions,
            }
          );
          return {
            ...state,
            inviteUsersViaCSVStatus: "succeeded",
          };
        }
      )
      .addCase(inviteUsersViaCSV.rejected, (state) => {
        state.inviteUsersViaCSVStatus = "failed";
      })
      .addCase(inviteUserByEmail.pending, (state) => {
        state.inviteUserByEmailStatus = "loading";
      })
      .addCase(
        inviteUserByEmail.fulfilled,
        (
          state: GlobalState,
          {
            payload: {
              createdUsers,
              createdTeamMemberships,
              teamId,
              sendInvitations,
              companySettings,
              isAdmin,
            },
          }: PayloadAction<{
            createdUsers: UserInfo[];
            createdTeamMemberships: TeamMember[];
            teamId?: number;
            sendInvitations?: boolean;
            companySettings?: CompanySettings | null;
            isAdmin: boolean;
          }>
        ) => {
          toast.success(
            `${sendInvitations ? "Inviting" : "Adding"} Users Successful!`,
            successNotificationOptions
          );

          const newUsers: { [userAccountId: number]: UserInfo } = {};
          const updatedTeamObject: { [teamId: number]: Team } = teamId
            ? { [teamId]: state.teamsByTeamId[teamId] }
            : {};

          const team = updatedTeamObject[teamId ?? 0];
          const teamMembers: TeamMember[] = [...(team?.teamMembers ?? [])];
          const pendingTeamMemberIds: number[] = [
            ...(team?.pendingTeamMemberIds ?? []),
          ];

          createdUsers.forEach((user) => {
            // If no user exist then we save that user into the newUsers object to add it to state
            if (!state.usersInfoById[user.userAccountId]) {
              newUsers[user.userAccountId] = user;
            }

            // If no teamId exist then we return so we don't add the user to the team
            if (!teamId) {
              return;
            }

            const newMember = createdTeamMemberships?.find(
              (teamMember) => teamMember.userAccountId === user.userAccountId
            );

            // If no new membership exist then we return so we don't add the user to the team
            if (!newMember) {
              return;
            }

            if (!isAdmin || companySettings?.requireTeamAcceptance) {
              pendingTeamMemberIds.push(user.userAccountId);
            }

            teamMembers.push(newMember);
          });

          if (teamId) {
            updatedTeamObject[teamId] = {
              ...state.teamsByTeamId[teamId],
              teamMembers,
              teamMemberIds: teamMembers?.map(
                (teamMember) => teamMember.userAccountId
              ),
              pendingTeamMemberIds,
            };
          }

          return {
            ...state,
            inviteUserByEmailStatus: "succeeded",
            usersInfoById: {
              ...state.usersInfoById,
              ...newUsers,
            },
            teamsByTeamId: {
              ...state.teamsByTeamId,
              ...updatedTeamObject,
            },
            invitedCount: createdUsers.length,
          };
        }
      )
      .addCase(inviteUserByEmail.rejected, (state, e) => {
        const invalidEmails: string[] = [];
        if (e?.payload) {
          invalidEmails.push(...Object.values(e.payload));
        }
        return {
          ...state,
          inviteUserByEmailStatus: "failed",
          invalidInvitedStrings: invalidEmails,
        };
      })
      .addCase(getTeamInviteLink.pending, (state) => {
        state.getTeamInviteLinkStatus = "loading";
      })
      .addCase(
        getTeamInviteLink.fulfilled,
        (
          state,
          {
            payload: { teamId, response },
          }: PayloadAction<{ teamId: number; response: { link: string } }>
        ) => {
          return {
            ...state,
            getTeamInviteLinkStatus: "succeeded",
            teamInviteLinks: {
              ...state.teamInviteLinks,
              [teamId]: response.link,
            },
          };
        }
      )
      .addCase(getTeamInviteLink.rejected, (state) => {
        state.getTeamInviteLinkStatus = "failed";
      })
      .addCase(getTeamByTeamId.pending, (state) => {
        state.getTeamByTeamIdStatus = "loading";
      })
      .addCase(
        getTeamByTeamId.fulfilled,
        (state, action: PayloadAction<{ response: Team; teamId: number }>) => {
          const {
            payload: { teamId, response },
          } = action;
          if (response.isSampleTeam) {
            return {
              ...state,
              getTeamByTeamIdStatus: "succeeded",
              sampleTeamsByTeamId: {
                ...state.sampleTeamsByTeamId,
                [teamId]: response,
              },
            };
          }
          const teamsByTeamId = {
            ...state.teamsByTeamId,
            [teamId]: {
              ...state.teamsByTeamId[teamId],
              ...response,
              searchedByTeamId: true as const,
            },
          };

          return {
            ...state,
            getTeamByTeamIdStatus: "succeeded",
            teamsByTeamId,
          };
        }
      )
      .addCase(getTeamByTeamId.rejected, (state, e) => {
        const teamId = e.meta.arg;
        return {
          ...state,
          getTeamByTeamIdStatus: "failed",
          teamsByTeamId: {
            ...state.teamsByTeamId,
            [teamId]: {
              ...state.teamsByTeamId[teamId],
              private: 1,
            },
          },
        };
      })
      .addCase(getAllTeamsByCompanyAccountId.pending, (state) => {
        state.getAllTeamsByCompanyAccountIdStatus = "loading";
      })
      .addCase(
        getAllTeamsByCompanyAccountId.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            [teamId: number]: Team;
          }>
        ) => {
          const copyTeamsByTeamId = Object.assign({}, state.teamsByTeamId);
          const sampleTeams: {
            [teamId: string]: Team;
          } = {};

          Object.entries(payload).forEach(([id, teamObj]) => {
            const teamId = Number(id);
            if (teamObj.isSampleTeam) {
              sampleTeams[teamId] = teamObj;
            } else {
              copyTeamsByTeamId[teamId] = {
                ...copyTeamsByTeamId[teamId],
                ...teamObj,
              };
            }
          });

          return {
            ...state,
            getAllTeamsByCompanyAccountIdStatus: "succeeded",
            teamsByTeamId: copyTeamsByTeamId,
            sampleTeamsByTeamId: sampleTeams,
            allTeamsIncludingSample: sampleTeams,
          };
        }
      )
      .addCase(getAllTeamsByCompanyAccountId.rejected, (state) => {
        state.getAllTeamsByCompanyAccountIdStatus = "failed";
      })
      .addCase(removeTeamMemberFromTeam.pending, (state) => {
        state.removeTeamMemberFromTeamStatus = "loading";
      })
      .addCase(
        removeTeamMemberFromTeam.fulfilled,
        (
          state,
          {
            payload: { teamId, teamMemberId, userAccountId },
          }: PayloadAction<{
            teamMemberId: number;
            teamId: number;
            userAccountId: number;
          }>
        ) => {
          toast.success("Removing Team Member Successful!", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            removeTeamMemberFromTeamStatus: "succeeded",
            teamsByTeamId: {
              ...state.teamsByTeamId,
              [teamId]: {
                ...state.teamsByTeamId[teamId],
                teamMembers: state.teamsByTeamId[teamId].teamMembers?.filter(
                  (teamMember) => teamMember.teamMemberId !== teamMemberId
                ),
                teamMemberIds: state.teamsByTeamId[
                  teamId
                ].teamMemberIds?.filter(
                  (memberId) => memberId !== userAccountId
                ),
                pendingTeamMemberIds: state.teamsByTeamId[
                  teamId
                ].pendingTeamMemberIds?.filter(
                  (memberId) => memberId !== userAccountId
                ),
              },
            },
          };
        }
      )
      .addCase(removeTeamMemberFromTeam.rejected, (state) => {
        state.removeTeamMemberFromTeamStatus = "failed";
      })
      .addCase(
        deleteUserByUserAccountId.fulfilled,
        (
          state,
          {
            payload: { userAccountId },
          }: PayloadAction<{ userAccountId: number }>
        ) => {
          const usersByIdCopy = { ...state.usersInfoById };
          delete usersByIdCopy[userAccountId];
          return {
            ...state,
            userDeletedStatus: "succeeded",
            usersInfoById: usersByIdCopy,
          };
        }
      )
      .addCase(deleteUserByUserAccountId.pending, (state) => {
        state.userDeletedStatus = "loading";
      })
      .addCase(deleteUserByUserAccountId.rejected, (state) => {
        state.userDeletedStatus = "failed";
      })
      .addCase(editTeamMemberFromTeam.pending, (state) => {
        state.updateTeamMemberByTeamMemberIdStatus = "loading";
      })
      .addCase(
        editTeamMemberFromTeam.fulfilled,
        (
          state,
          {
            payload: { teamId, payload, teamMemberId },
          }: PayloadAction<{
            payload: { isTeamLead: 0 | 1 };
            teamMemberId: number;
            teamId: number;
          }>
        ) => {
          toast.success("Updating Team Member Successful!", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updateTeamMemberByTeamMemberIdStatus: "succeeded",
            teamsByTeamId: {
              ...state.teamsByTeamId,
              [teamId]: {
                ...state.teamsByTeamId[teamId],
                teamMembers: state.teamsByTeamId[teamId].teamMembers?.map(
                  (teamMember) =>
                    teamMember.teamMemberId !== teamMemberId
                      ? teamMember
                      : { ...teamMember, ...payload }
                ),
              },
            },
          };
        }
      )
      .addCase(editTeamMemberFromTeam.rejected, (state) => {
        state.updateTeamMemberByTeamMemberIdStatus = "failed";
      })
      .addCase(getAppCuesInformation.pending, (state) => {
        state.getAppCuesInformationStatus = "loading";
      })
      .addCase(getAppCuesInformation.fulfilled, (state, action) => {
        state.getAppCuesInformationStatus = "succeeded";
        state.appCuesInformation = action.payload;
      })
      .addCase(getAppCuesInformation.rejected, (state) => {
        state.getAppCuesInformationStatus = "failed";
      });
  },
});

// ------------------------------------ Selectors ------------------------------------
export const selectGetAllTeamsStatus = (state: RootState) =>
  state.global.getAllTeamsByCompanyAccountIdStatus;
export const selectCurrentUserAccountId = (state: RootState) =>
  state.global.currentUserAccountId;
export const selectInviteUserByEmailStatus = (state: RootState) =>
  state.global.inviteUserByEmailStatus;
export const selectInvitedCount = (state: RootState) =>
  state.global.invitedCount;
export const selectUpdateTeamStatus = (state: RootState) =>
  state.global.updateTeamStatus;
export const selectCreateTeamStatus = (state: RootState) =>
  state.global.createTeamStatus;
export const selectDeleteTeamStatus = (state: RootState) =>
  state.global.deleteTeamStatus;
export const selectCompanyInfo = (state: RootState) => state.global.companyInfo;
export const selectGetAllUserStatus = (state: RootState) =>
  state.global.getAllUsersStatus;
export const selectSampleUsersInfoById = (state: RootState) =>
  state.global.sampleUsersInfoById;
export const selectAllCompanyUsersById = (state: RootState) =>
  state.global.usersInfoById;
export const selectIsCurrentUserAdmin = (state: RootState) =>
  state.global.currentUserAccountId &&
  state.global.usersInfoById[state.global.currentUserAccountId]?.tmgRoleId ===
    1;
export const selectCurrentUserInfo = (state: RootState) => {
  if (!state.global.currentUserAccountId) {
    return null;
  }
  return state.global.usersInfoById[state.global.currentUserAccountId];
};
export const selectUserInfoById =
  (userAccountId: number) => (state: RootState) =>
    state.global.usersInfoById[userAccountId];
export const selectGetUserInfoByIdStatus = (state: RootState) =>
  state.global.getUserInfoByIdStatus;
export const selectGetUserStatus = (state: RootState) =>
  state.global.getUserStatus;
export const selectUpdateUserInfoStatus = (state: RootState) =>
  state.global.updateUserInfoStatus;
export const selectInviteUsersViaCSVStatus = (state: RootState) =>
  state.global.inviteUsersViaCSVStatus;
export const selectSampleTeamsByTeamId = (state: RootState) =>
  state.global.sampleTeamsByTeamId;
export const selectTeamsByTeamId = (state: RootState) =>
  state.global.teamsByTeamId;
export const selectTeamsByTeamIdStatus = (state: RootState) =>
  state.global.getTeamByTeamIdStatus;
export const selectInviteLinkByTeamId = (state: RootState) =>
  state.global.teamInviteLinks;
export const selectAppCuesInformation = (state: RootState) =>
  state.global.appCuesInformation;
export const selectUpdateTeamMemberByTeamMemberIdStatus = (state: RootState) =>
  state.global.updateTeamMemberByTeamMemberIdStatus;
export const selectRemoveTeamMemberFromTeamStatus = (state: RootState) =>
  state.global.removeTeamMemberFromTeamStatus;
export const selectUpdateUserByIdStatus = (state: RootState) =>
  state.global.updateUserByIdStatus;
export const selectUserDeletedStatus = (state: RootState) =>
  state.global.userDeletedStatus;
export const selectInvalidInvitedStrings = (state: RootState) =>
  state.global.invalidInvitedStrings;
export const selectGetCompanyInfoStatus = (state: RootState) =>
  state.global.getCompanyInfoStatus;
export const selectGetAppCuesInformationStatus = (state: RootState) =>
  state.global.getAppCuesInformationStatus;
export const selectConfigCatFlags = (state: RootState) =>
  state.global.configCatFlags;
export const selectConfigCatFlag = (key: string) => (state: RootState) => {
  // for testing purposes
  // if (key === "develop_coachboconversationhistory") {
  //   return false;
  // }
  return state.global.configCatFlags[key] ?? false;
};
export const selectGetConfigCatFlagsStatus = (state: RootState) =>
  state.global.getConfigCatFlagsStatus;
export const selectCreatedButNotInvitedUsersIds = (state: RootState) =>
  state.global.createdButNotInvitedUsersIds;

export const {
  setGodMode,
  clearCurrentUserAccountId,
  deleteUsersProfilePicture,
  updateUsersProfilePicture,
  resetCreateTeamStatus,
  resetDeleteTeamStatus,
  updateUserImage,
  updateTeamImage,
  clearInviteCount,
  resetUserDeletedState,
  resetUpdateUpdateUserInfoStatus,
  removeTeamIdFromUserAccountId,
  resetInvalidInvitedStrings,
  acceptTeamInvitation,
  declineTeamInvitation,
  setConfigCatFlag,
  setConfigCatFlagStatus,
  updateUserInfoById,
  updateUsersAction,
  removeUsersFromCreatedButNotInvitedUsersIds,
  updateTeamsAction,
} = globalSlice.actions;

export default globalSlice.reducer;
